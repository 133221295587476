<template>
  <div class="OrderDetails">
    <div class="OrderDetails_tit">订单详情</div>

    <div class="displayFlex">
      <div class="flexInner">
        <div class="width80">订单号</div>
        <div>
          <a-tooltip>
            <template slot="title">
              {{ formInfo.orderNo }}
            </template>
            {{ formInfo.orderNo }}
          </a-tooltip>
        </div>
      </div>
      <div class="flexInner">
        <div class="width80">送货地址</div>
        <div>
          <a-tooltip>
            <template slot="title">
              {{ formInfo.receivingAddress }}
            </template>
            {{ formInfo.receivingAddress }}
          </a-tooltip>
        </div>
      </div>
      <div class="flexInner">
        <div class="width80">收货人</div>
        <div>
          <a-tooltip>
            <template slot="title">
              {{ formInfo.receivingName }}
            </template>
            {{ formInfo.receivingName }}
          </a-tooltip>
        </div>
      </div>
      <div class="flexInner">
        <div class="width80">联系电话</div>
        <div>
          <a-tooltip>
            <template slot="title">
              {{ formInfo.phone }}
            </template>
            {{ formInfo.phone }}
          </a-tooltip>
        </div>
      </div>
      <div class="flexInner">
        <div class="width80">供应商</div>
        <div>
          <a-tooltip>
            <template slot="title">
              {{ formInfo.supplierName }}
            </template>
            {{ formInfo.supplierName }}
          </a-tooltip>
        </div>
      </div>
      <div class="flexInner">
        <div class="width80">下单时间</div>
        <div>{{ formInfo.createTime }}</div>
      </div>
      <div class="flexInner">
        <div class="width80">配送方式</div>
        <div>{{ formInfo.deliveryMethod | DeliveryMethodList }}</div>
      </div>
      <div class="flexInner">
        <div class="width80">主要货物</div>
        <div>
          <a-tooltip>
            <template slot="title">
              {{ formInfo.mainGoods }}
            </template>
            {{ formInfo.mainGoods }}
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="table-button-flex">
      <div>
        <a-button type="primary" @click="cancelOrder()">退货</a-button>
      </div>
    </div>
    <a-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="tableColumns"
      :data-source="dataSource"
      :pagination="false"
      :scroll="{ x: '1300px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <!-- 材料类别 -->
      <span slot="type" slot-scope="text, record">
        {{ record.material.typeName }}
      </span>
      <!-- 材料名称 -->
      <span slot="deliveryState" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ record.material.name }}
          </template>
          {{ record.material.name }}
        </a-tooltip>
      </span>

      <!-- exchangeNumber退换货情况 -->
      <span slot="exchangeNumber" slot-scope="text, record">
        <div v-if="text == 0 && record.returnNumber == 0">无退换</div>
        <div v-else>
          <span class="padding_R">
            {{ text > 0 ? "有换货" : "" }}
          </span>
          <span>{{ record.returnNumber > 0 ? "有退货" : "" }}</span>
        </div>
      </span>
    </a-table>
    <div class="totalPrice">
      <div>下单小计</div>
      <div>{{ totalPrice }}</div>
    </div>
    <div class="totalPrice">
      <div>实发小计</div>
      <div>{{ ActualtotalPrice }}</div>
    </div>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="true"
      :visible="visibleDrawer"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <ReturnInformation :record="record" @onClose="onClose" />
    </a-drawer>
  </div>
</template>

<script>
import ReturnInformation from "./ReturnInformation";
import { GetCompanies } from "@/api/device";

export default {
  name: "OrderDetails",
  components: {
    ReturnInformation,
  },
  data() {
    return {
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      formInfo: {},
      CompanyListid: [],
      DrawerTitle: "",
      visibleDrawer: false,
      record: "",
      totalPrice: 0,
      ActualtotalPrice: 0,
      tableColumns: [
        {
          title: "材料编号",
          width: "100px",
          dataIndex: "materialCode",
          align: "center",
        },
        {
          title: "材料类别",
          width: "100px",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "材料名称",
          // width: "90px",
          dataIndex: "deliveryState",
          scopedSlots: { customRender: "deliveryState" },
          align: "center",
          ellipsis: true,
        },
        {
          title: "型号",
          width: "100px",
          dataIndex: "material.modelCode",
          align: "center",
        },

        {
          title: "单位",
          width: "90px",
          dataIndex: "material.unit",
          align: "center",
        },
        {
          title: "单价(元)",
          width: "90px",
          dataIndex: "supplierPrice",
          align: "center",
        },
        {
          title: "下单数量",
          width: "90px",
          dataIndex: "number",
          align: "center",
        },
        {
          title: "下单小计(元)",
          width: "90px",
          dataIndex: "total",
          align: "center",
        },
        {
          title: "实发数量",
          width: "90px",
          dataIndex: "sentNumber",
          align: "center",
        },
        {
          title: "实发小计(元)",
          width: "90px",
          dataIndex: "factTotal",
          align: "center",
        },
        {
          title: "剩余应发数量",
          width: "100px",
          dataIndex: "leaveNumber",
          align: "center",
        },
        {
          title: "退换货情况",
          width: "100px",
          dataIndex: "exchangeNumber",
          scopedSlots: { customRender: "exchangeNumber" },
          align: "center",
        },
      ],
    };
  },
  created() {
    // this.GetCompaniesId();
    let query = JSON.parse(this.$route.query.record);
    console.log("query", query);
    this.formInfo = query;
    this.dataSource = query.orderDetailsList;
    let totalPrice = 0;
    let ActualtotalPrice = 0;
    query.orderDetailsList.forEach((el) => {
      totalPrice = totalPrice * 1 + el.total * 1;
      ActualtotalPrice = ActualtotalPrice * 1 + el.factTotal * 1;
    });
    this.totalPrice = totalPrice;
    this.ActualtotalPrice = ActualtotalPrice;
  },
  mounted() {},
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          this.CompanyListid = [];
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.companyId == "") {
            this.queryParam.companyId = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      //   console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    cancelOrder() {
      this.record = JSON.stringify(this.selectedRows);
      this.visibleDrawer = true;
    },
    onClose() {
      this.visibleDrawer = false;
    },
  },
};
</script>
<style lang="less" scoped>
.OrderDetails {
  margin-top: 50px;
  .OrderDetails_tit {
    text-align: left;
    font-size: 24px;
    font-weight: 800;
  }
  .table-button-flex {
    text-align: left;
    margin: 10px 0;
  }
}
.displayFlex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .flexInner {
    display: flex;
    width: 25%;

    text-align: left;

    div {
      //   width: 200px;
      font-weight: 800;
      margin: 10px 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .width80 {
      width: 80px;
      margin: 10px 0;
    }
  }
}
.totalPrice {
  display: flex;
  width: 100%;
  // background-color: pink;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 800;
  margin-top: 20px;
}
.padding_R {
  padding-right: 10px;
}
</style>