<template>
  <div class="ReturnInformation">
    <div class="alertName">
      <a-alert
        message="此处仅支持退未发货的物料，已发货的部分请去“发货查询”订单详情页退换"
        type="info"
        show-icon
      />
    </div>

    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <!-- @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            " -->
      <div v-for="item in form" :key="item.id">
        <a-form-model-item
          ref="thisReturnNumber"
          :label="item.material.name"
          prop="thisReturnNumber"
        >
          <a-input-number
            v-model="item.thisReturnNumber"
            :min="0"
            :max="item.leaveNumber"
            placeholder="请输入数量"
            @change="inputNumberChange"
          />
        </a-form-model-item>
        <a-form-model-item label="退货原因" prop="remark">
          <a-input
            v-model="item.remark"
            type="textarea"
            placeholder="请描述退货原因"
          />
        </a-form-model-item>
      </div>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
        <a-button type="primary" @click="onSubmit"> 确定 </a-button>
        <a-button style="margin-left: 10px" @click="resetForm"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { OrderReturn } from "@/api/apiJF/material";
export default {
  name: "ReturnInformation",
  components: {},
  props: {
    record: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      labelCol: { span: 9 },
      wrapperCol: { span: 10 },
      other: "",
      form: [
        {
          thisReturnNumber: 0,
          remark: "",
        },
        {
          thisReturnNumber: 0,
          remark: "",
        },
        {
          thisReturnNumber: 0,
          remark: "",
        },
        {
          thisReturnNumber: 0,
          remark: "",
        },
      ],
      recordForm: [],
      rules: {
        thisReturnNumber: [
          // {
          //   required: true,
          //   message: "请输入不大于未发货量的数量",
          //   trigger: "blur",
          // },
          //   {
          //     min: 3,
          //     max: 5,
          //     message: "此处仅支持未发货的物料",
          //     trigger: "blur",
          //   },
        ],
      },
    };
  },
  created() {
    let record = JSON.parse(this.record);
    console.log("record", record);
    this.recordForm = record;
    this.form = this.recordForm;
    this.recordForm.forEach((el, recordFormIndex) => {
      // this.form.forEach((item, formIndex) => {
      //   item.thisReturnNumber = el.leaveNumber * 1;
      //   console.log(" el.leaveNumber", el.leaveNumber);
      //   item.remark = "";
      // });
      this.form[recordFormIndex].thisReturnNumber = el.leaveNumber * 1;
      this.form[recordFormIndex].remark = "";
    });
    this.form = this.form.splice(0, this.recordForm.length);
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log("this.form", this.form);
          this.OrderReturn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.push({
        name: "AlreadyOrder",
      });
    },
    onClose() {
      this.visibleDrawer = false;
    },
    //退货
    OrderReturn() {
      let params = this.form;
      OrderReturn(params).then((res) => {
        // console.log("res", res.data);
        if (res.code == 200) {
          this.$message.success("退货成功");
          this.onClose();
          this.$router.push({
            name: "AlreadyOrder",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    inputNumberChange() {
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
.alertName {
  margin: 20px;
  width: 70%;
}
/deep/.ant-alert-info {
  background-color: #eaf2ff;
  border: none;
  border-radius: 10px;
}
</style>